//将所有接口都集中写在此处方便管理
import request from './request'

/**
 * line user logout
 */
export function LineLogout() {
    localStorage.setItem('auth', '{}');
    window.location.reload();
}
//验证碼
export const getCaptcha = () => request.get('/api/front/validate/code/get');

//email账号登入
export const GetUserLogin = (params) => request.post('/api/front/login', params);

//email寄送重设密码网址
export const UserLostPassword = (params) => request.post('/api/front/lostpassword', params);

//email中打开网址重置密码
export const UserResetPassword = (params) => request.post('/api/front/resetpassword', params);

//账号登出
export const GetUserLogout = () => request.get('/api/front/logout');

//判断是否已登入
export const isLogined=(params) => request.get('/api/front/login/status', {params});
//账号修改密码
export const MdfUserPwd = (params) => request.post('/api/front/user/editpwd', params);

//修改个人资料
export const MdfUser = (params) => request.post('/api/front/user/edit', params);

//账号信息
export const GetUser = (params) => request.get('/api/front/user', {params});

//首頁banner
export const GetIndexBannerList = () => request.get('/api/front/banner/indexList');

//首頁导航列表
export const GetIndexNavbarList = () => request.get('/api/front/navbar/indexList');

export const GetLogo = () => request.get('/api/front/header/logo');

//网站seo
export const GetIndexSeo = () => request.get('/api/front/indexseo');

//文章详情 api/front/article/info/{id}
export const GetArticleInfoById = (id) => request.get('/api/front/article/info/' + id, {id});

//店铺列表
export const GetStoreList = (params) => request.get('/api/front/store/list', {params});

//取得超店信息
export const GetSzfuMapStoreInfo = (params) => request.get('/api/public/szfu/map/callback', {params});

//取得商品分类列表
export const GetGoodsCategoryList = (params) => request.get('/api/front/category', {params});
//取得商品列表
export const GetGoodsList = (params) => request.get('/api/front/products', {params});
//商品详情
export const GetGoodsDetail = (id) => request.get('/api/front/product/detail/'+id, {id});

//加入购物车
export const CartAdd = (params) => request.post('/api/front/cart/save', params);

//购物车商品数量
export const CartCount = (params) => request.get('/api/front/cart/count', {params});

//购物车商品列表
export const GetCartList = (params) => request.get('/api/front/cart/all', {params});

//购物车商品删除
export const CartDelete = (params) => request.post('/api/front/cart/delete',params);

//购物车商品修改数量
export const CartNum = (params) => request.post('/api/front/cart/num',params);
//城市列表
export const GetCityList = (params) => request.get('/api/front/city/list', {params});

export const GetCityListById = (params) => request.get('/api/front/city/list/' + params, {params});

//我的地址列表
export const GetAddressList = (params) => request.get('/api/front/address/list', {params});
//我的默认地址
export const GetDefaultAddress = (params) => request.get('/api/front/address/default', {params});

//我的收货默认地址
export const GetRcvDefaultAddress = (params) => request.get('/api/front/address/rcvdefault', {params});
//地址信息
export const GetAddressById = (id) => request.get('/api/front/address/detail/'+id);
//删除 地址
export const DelCityListById = (params) => request.post('/api/front/address/del', params);
//修改or保存地址
export const SaveAddress = (params) => request.post('/api/front/address/edit',params);

//订单列表
export const OrderList = (type,params) => request.get('/api/front/order/list?type='+type,{params});
//订单详情
export const OrderDetail = (id, params) => request.get('/api/front/order/detail/' + id, {params});

//订单取消
export const OrderCancel = (params) => request.post('/api/front/order/cancel', params);

export const OrderRefundApply = (params) => request.post('/api/front/order/refundApply', params);

//订单申请退货
export const OrderRefund = (params) => request.post('/api/front/order/refund', params);

//订单作废
export const OrderDel = (params) => request.post('/api/front/order/del', params);

//创建订单
export const OrderCreate = (params) => request.post('/api/front/order/create',params);

//取消原因列表
export const CancelReasonList = () => request.get('/api/front/order/refund/reason');

//订单去付款
export const OrderPayment = (params) => request.post('/api/front/pay/payment',params);

//partner登陆授权网址
export const GetPartnerAuthUrl = (loginType) => request.get('/api/public/oauth/' + loginType + '/auth', {loginType});

export const GetPartnerAuthDataResult = function (params) {
    //return request.get('/api/public/oauth/goose/login/callback/result', {params});
    return request.get('/api/public/oauth/login/callback/result', {params});
};
//得到partner授权成功后,后端注册成功返回结果
// export const GetPartnerAuthData = function (params) {
//     if ("line" == params.loginType) {
//         return request.get('/api/public/oauth/goose/login/line/callback', {params});
//     }
//     if ("google" == params.loginType) {
//         return request.get('/api/public/oauth/goose/login/google/callback', {params});
//     }
//     if ("facebook" == params.loginType) {
//         return request.get('/api/public/oauth/goose/login/facebook/callback', {params});
//     }
//
// };

//line登陆授权网址
export const GetLineAuthUrl = (params) => request.get('/api/public/oauth/line/auth', {params});
//得到line授权成功后,后端注册成功返回结果
export const GetLineAuthData = (params) => request.get('/api/public/oauth/line/callback', {params});

//google登陆授权网址
export const GetGoogleAuthUrl = (params) => request.get('/api/public/oauth/google/auth', {params});
//得到google授权成功后,后端注册成功返回结果
export const GetGoogleAuthData = (params) => request.get('/api/public/oauth/google/callback', {params});


//facebook登陆授权网址
export const GetFacebookAuthUrl = (params) => request.get('/api/public/oauth/facebook/auth', {params});
//得到facebook授权成功后,后端注册成功返回结果
export const GetFacebookAuthData = (params) => request.get('/api/public/oauth/facebook/callback', {params});



//得到line notify授权成功后后端返回结果
export const GetLineNotifyAuthData = (params) => request.get('/api/public/line/notifyAuth', {params});

//
//请求首頁-曾经买过
export const GetHomeBuyHisData = (params) => request.get('/api/front/mall/list/1', {params});

//请求首頁-附近门店
export const GetHomeNearbyData = (params) => request.get('/api/front/mall/store/nearby', {params});

//门店资料及分类&分类下的产品列表
export const GetStoreHomeData = (params) => request.get('/api/front/mall/storehome', {params});

//单品减少数量
export const CartMinus = (params) => request.post('/api/front/cart/minus', params);

//清空购物车商品
export const CartClear = (params) => request.get('/api/front/cart/clear', {params});

//购物车结账产生单号
export const CartCheckOut = (params) => request.post('/api/front/cart/checkOut', params);

//单條地址信息
export const GetAddress = (params) => request.get('/api/front/cart/address/list/' + params, {params});
//单條地址信息
export const DelAddress = (params) => request.get('/api/front/cart/address/del/' + params, {params});
// 取得google地址
export const GetAddressFromGoogle = (params) => request.get('/api/front/cart/address/geocode', {params});

//取得google地址
/*export const GetGoogleAddressJson = (params) => {
    return $getJson('https://maps.google.cn/maps/api/geocode/json',params)
}*/

//取得條码值
export const GetQrcodeValue = (params) => request.get('/api/front/cart/qrcode', {params});

//点击搜索框,取得popup頁中的初始数据
export const GetStorePopupData = (params) => request.get('/api/front/mall/store/popup', {params});//原GetPopupData

//点击搜索进行搜索
export const GetStoreSrhData = (params) => request.get('/api/front/mall/store/search', {params});//原GetSearchData

//搜索关键词下拉提醒列表(最多10笔)
export const GetStoreSrhHelpData = (params) => request.get('/api/front/mall/store/searchhelper', {params});//原GetSearchHelpData

//清除搜索历史关键词
export const ClearStoreSrhHistoryData = (params) => request.get('/api/front/mall/store/clearhistory', {params});//原ClearSearchHistoryData

//会员中心首頁数据
export const GetCustHomeData = (params) => request.get('/api/front/cust/home', {params});//会员中心首頁数据

//订单付款确认
export const CartCheckOutConfirm = (params) => request.post('/api/front/order/confirm', params);

//购物车结账-linepay付款发起
export const CartLinePayRequest = (params) => request.post('/api/front/linepay/reserve', params);

//我的订单列表
export const GetCustOrderData = (params) => request.get('/api/front/order/list', {params});//会员中心首頁数据

//我的积分列表
export const GetPointList = (params) => request.get('/api/front/cust/pointList', {params});
//会员余额
export const GetCustomerDeposit = () => request.get('/api/front/cust/deposit');

//订单详细信息
export const GetCustOrderInfoData = (params) => request.get('/api/front/order/detail/' + params, {params});//会员中心首頁数据

//订单取消
// export const GetCustOrder2Cancel = (params) => request.get('/api/front/order/cancel/' + params, {params});
//---------------------------
//请求首頁数据
export const GetHomeData = () => request.get('/index/index');
//登陆&注册{username:"",pwd:""}
export const LoginAndRegist = (params) => request.post('/auth/loginByWeb', params);

//单品頁-相关产品{id:''}
export const GetGoodsRelatedData = (params) => request.get('/goods/related', {params});
//购物车数量修改
export const CartUpdate = (params) => request.post('/cart/update', params);
//购物车中商品被选中或者取消
export const CartGoodsChecked = (params) => request.post('/cart/checked', params);
