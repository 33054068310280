import Vue from 'vue';
import Vuex from 'vuex';
import {Message} from 'element-ui';
import {getToken, removeToken, setToken} from '@/utils/auth';
import {CartAdd, CartCount, GetUser, GetUserLogin, isLogined} from '@/request/api';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        token: getToken(),
        user: {},
        logined: false,
        cartNum: 0,
        rcvAddress: {},
        isMobile:false,
        navBarFixed:false,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_USER_NICKNAME(state, nickName) {
            state.user.nickName = nickName;
        },
        SET_USER_REALNAME(state, realName) {
            state.user.realName = realName;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_LOGINED: (state, status) => {
            state.logined = status;
        },
        SET_RCVADDRESS: (state, rcvAddress) => {
            state.rcvAddress = rcvAddress;
        },
        SET_CARTNUM: (state, num) => {
            state.cartNum = num;
        },
        SET_MOBLIE: (state, value)=>{
            state.isMobile = value;
        },
        SET_NAVBARFIXED:(state,navBarFixed)=>{
            state.navBarFixed = navBarFixed;
        }
    },
    actions: {
        /**
         async actionA ({ commit }) {
            commit('gotData', await getData())
          },
         async actionB ({ dispatch, commit }) {
            await dispatch('actionA') // 等待 actionA 完成
            commit('gotOtherData', await getOtherData())
          }
         * @param commit
         */
        async initDatas({commit}) {
            await store.dispatch('getUser').then(res => {
                if (res.code != 200) {
                    //console.error('store initdata dispatch GetUser', res);
                    //Message({message: res.message, type: 'error'});
                    return;
                }
                commit('SET_USER', res.data);
                commit('SET_LOGINED', true);
                store.dispatch('getCartNum').then(res => {
                    if (res.code != 200) {
                        //Message({message: res.message, type: 'error'});
                        return;
                    }
                    commit('SET_CARTNUM', res.data.count);
                });
            }).catch(err => {
                Message({message: err, type: 'error'});
            });
        },
        isLogined({commit}) {
            isLogined().then(res => {
                if (res.code == 200) {
                    commit('SET_LOGINED', res.data);
                }
            }).catch(err => {
                this.$message.error(err);
            });
        },
        getCartNum({commit}) {
            return new Promise((resolve, reject) => {
                CartCount({type: 'sum', numType: true,}).then(res => {
                    if (res.code == 200) {
                        commit('SET_CARTNUM', res.data.count);
                        commit('SET_LOGINED', true);
                    }
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });
        },
        add2Cart({ commit}, {productId, productAttrUnique, cartNum}) {
            return new Promise((resolve, reject) => {
                CartAdd({
                    productId: productId,
                    productAttrUnique: productAttrUnique,
                    cartNum: cartNum,
                    isNew: false,
                }).then(res => {
                    if (res.code == 200) {
                        commit('SET_LOGINED', true);
                        Message({message: '成功加入購物車', type: 'success'});
                        store.dispatch('getCartNum');
                    } else if (res.code == 401) {
                        Message({message: res.message, type: 'error'});
                        //_this.$router.push('/login');
                    } else {
                        Message({message: res.message, type: 'error'});
                    }
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    Message({message: '網路連線失敗，稍候請重試!',type: 'error'});
                    reject(err)
                    // this.$message.error('網路連線失敗，稍候請重試!');
                });
            });
        },
        // user login 暂时不能跳提醒与redirect 而未调用
        login({commit}, userLogin) {
            return new Promise((resolve, reject) => {
                GetUserLogin(userLogin).then(res => {
                    if (res.code == 200) {//取得line授权网址并跳转
                        commit('SET_USER', res.data);
                        commit('SET_LOGINED', true);
                        var auth = {"token": res.data.token, "nickName": res.data.nickName, "avatar": res.data.avatar};
                        setToken(JSON.stringify(auth));
                    }
                    resolve();
                }).catch(error => {
                    reject(error)
                });
            });
        },
        // get user info
        getUser({commit}) {
            return new Promise((resolve, reject) => {
                GetUser({}).then(res => {
                    if (res.code == 200) {
                        commit('SET_USER', res.data);
                        resolve(res);
                    } else if (res.code==401){
                        resolve(res);
                    } else {
                        reject(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    reject('網路連線失敗，稍候請重試!');
                });
            })
        },

        // user logout
        logout({commit}) {
            return new Promise((resolve) => {
                commit('SET_USER', {});
                removeToken();
                localStorage.clear();
                resolve();
            })
        },
    }
});

export default store;
