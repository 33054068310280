<template>
    <div id="app">
        <div>
            <Navbar :navBarFixed="navBarFixed"></Navbar>
        </div>
        <div v-if="$route.meta.indexFlg">
            <el-row>
                <el-carousel :interval="3000" arrow="always" indicator-position="none" :height="screenwidth" ref="slideCarousel">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="index" style="text-align: center;">
                        <img :src="item.image">
                    </el-carousel-item>
                </el-carousel>
            </el-row>
            <el-row>
                <Cards :rowCls="6" ref="cards" />
            </el-row>
        </div>
        <router-view/>
        <div style="height: 65px;"></div>
        <Footer/>
    </div>
</template>

<script>
    import {GetIndexBannerList,GetIndexSeo} from '@/request/api';
    import {mapActions} from 'vuex';
    import Navbar from '@/components/Navbar.vue';
    import Footer from '@/components/Footer.vue';
    import Cards from '@/components/Cards.vue';
    export default {
        name: 'App',
        components: {Navbar, Footer, Cards},
        data() {
            return {
                screenwidth: (document.documentElement.clientWidth * 668) / 1620 + "px",
                navBarFixed: false,
                bannerList: [],
            };
        },

        mounted() {
            this.initDatas();
            if (this.$route.meta.indexFlg) {
                window.onresize = () => {
                    return (() => {
                        if (document.documentElement.clientWidth > 960) {
                            this.screenwidth = (document.documentElement.clientWidth * 668) / 1620 + "px";
                        }else{
                            this.screenwidth = "197px";
                        }
                    })();
                };
            }
            if (this.$refs.slideCarousel) {
                this.slideBanner();
            }

            // 事件监听滚动條
            window.addEventListener('scroll', this.watchScroll);
        },
        methods: {
            ...mapActions(['initDatas']),
            watchScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                //  当滚动超过 60 时，实现吸顶效果
                if (scrollTop >= 60) {
                    this.navBarFixed = true
                } else if (scrollTop<=0) {
                    this.navBarFixed = false
                }
            },
            getIndexBannerList() {
                let _this = this;
                GetIndexBannerList({
                    id: ''
                }).then(res => {
                    if (res.code == 200) {
                        _this.bannerList = res.data;
                    } else {
                        console.log(res);
                    }
                }).catch(e => {
                    console.log(e);
                    // _this.$toast.fail('網絡異常,請銷候再試!' + e);
                });
            },
            slideBanner() {
                var box = document.querySelector('.el-carousel__container');//选中的轮播图
                var startPoint = 0;
                var stopPoint = 0;

                var resetPoint = function () { //重置坐标
                    startPoint = 0;
                    stopPoint = 0;
                }
                //手指按下
                box.addEventListener("touchstart", function (e) {
                    //手指点击位置的X坐标
                    startPoint = e.changedTouches[0].pageX;
                });
                //手指滑动
                box.addEventListener("touchmove", function (e) {
                    //手指滑动后终点位置X的坐标
                    stopPoint = e.changedTouches[0].pageX;
                });
                //当手指抬起的时候，判断图片滚动离左右的距离
                let that = this;
                box.addEventListener("touchend", function () {
                    if (stopPoint == 0 || startPoint - stopPoint == 0) {
                        resetPoint();
                        return;
                    }
                    if (startPoint - stopPoint > 0) {
                        resetPoint();
                        that.$refs.slideCarousel.next();
                        return;
                    }
                    if (startPoint - stopPoint < 0) {
                        resetPoint();
                        that.$refs.slideCarousel.prev();
                        return;
                    }
                });
            },
            getSeo(){
                GetIndexSeo().then(res => {
                    if (res.code == 200) {
                        let seo=res.data;
                        document.title = seo.seoTitle;
                        document.querySelector('meta[name="title"]').setAttribute('content', seo.seoTitle);
                        document.querySelector('meta[name="keywords"]').setAttribute('content', seo.seoKeywords);
                        document.querySelector('meta[name="description"]').setAttribute('content', seo.seoDescription);
                    }
                });
            }
        },
        created() {
            // 在頁面加载时读取sessionStorage里的状态信息
            if (sessionStorage.getItem('store')) {
                this.$store.replaceState(
                    Object.assign(
                        {},
                        this.$store.state,
                        JSON.parse(sessionStorage.getItem('store'))
                    )
                )
            }

            // 在頁面刷新时将vuex里的信息保存到sessionStorage里
            // beforeunload事件在頁面刷新时先触发
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem('store', JSON.stringify(this.$store.state));
            });
            this.getIndexBannerList();
            this.getSeo();
        },
    }
</script>

<style lang="less" scoped>
    #app {
        /*font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;

        margin-top: 50px;*/
        font-family: "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    }

    .el-carousel__container {
        height: 100% !important;
    }
    .el-carousel__item {
        img {
            display: inline-block;
            max-width: 100%;
        }
    }

</style>
