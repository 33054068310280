const TokenKey = 'magor3-auth'

export function getToken() {
    return localStorage.getItem(TokenKey);
}

export function setToken(token) {
    //localStorage.setItem("magor3-auth", JSON.stringify(auth));
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey);
}
