import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: 'Index',
        meta: {
            indexFlg: true
        }
    },
    {
        path: '/branch',
        name: 'Branch',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Branch.vue')
    },
    {
        path: '/page/:id',
        name: 'Page',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Page.vue')
    },
    {
        path: '/user-center',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/UserCenter.vue'),
        children: [
            {
                path: '/',
                name: 'UserCenter',
                component: () => import('../views/user-center/Index.vue')
            },
            {
                path: 'orders',
                name: 'Orders',
                component: () => import('../views/user-center/Orders.vue')
            },
            {
                path: 'address',
                name: 'Address',
                component: () => import('../views/user-center/RcvAddress.vue')
            },
            {
                path: 'user',
                name: 'User',
                component: () => import('../views/user-center/User.vue')
            },
            {
                path: 'mdfpwd',
                name: 'MdfPwd',
                component: () => import('../views/user-center/MdfPwd.vue')
            }
        ]
    },
    {
        path: '/category',
        name: 'Categorys',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Category.vue')
    },
    {
        path: '/category/:id',
        name: 'Category',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Category.vue')
    },
    {
        path: '/product/:id',
        name: 'Product',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Product.vue')
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Cart.vue')
    },
    {
        path: '/checkOut',
        name: 'CheckOut',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/CheckOut.vue')
    },
    {
        path: '/checkOutRcv',
        name: 'CheckOutRcv',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/CheckOutRcv.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/Login.vue')
    },
    {
        path: '/login/:type',
        name: 'LoginPartner',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/LoginPartner.vue')
    },
    {
        path: '/login/:type/callback',
        name: 'LoginCallback',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/LoginCallback.vue')
    },
    /*{
        path: '/login/:type/callback',
        name: 'LoginPartnerCallback',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/LoginPartnerCallback.vue')
    },*/
    {
        path: '/lost-password',
        name: 'LostPassword',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/LostPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: {
            indexFlg: false
        },
        component: () => import('../views/ResetPassword.vue')
    },

]

const router = new VueRouter({
    //如打包文件存放在Tomcat/webapps/bee下，那么base就这么写
    // base: '/goose/',
    mode: 'history',
    routes
})
const globalTitle = '蜂會員系統 - beemembers';
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : globalTitle;
    let auth = localStorage.getItem("magor3-auth");
    if (to.path != '/' && to.path.indexOf('/login')<0
        && to.path.indexOf('/category')<0
        && to.path.indexOf('/product')<0
        && to.path.indexOf('/page')<0
        && to.path != '/branch'
        && to.path != '/lost-password'
        && to.path != '/reset-password'
    ) {
        if (auth) {
            let authObj = JSON.parse(auth);
            if (authObj.token && authObj.token != "") {
                next();
                return;
            }
        }
        if (from.path == '/login') {
            return;
        }
        Vue.prototype.$message.error('登入已過期，請您重新登入!');
        next({
            path: '/login',
            query: {redirect: to.fullPath} // 将要跳转路由的path作为参数，传递到登录頁面
        })
        // next('/login');
        return;
    }
    next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => err);
};

export default router
