<template>
    <el-header class="my-navbar" :class="{'my-navbar-fix': navBarFixed}">
        <el-row :gutter="20" class="navbar-wrapper">
            <el-col :xl="6" :lg="6" :md="8" :sm="8" :xs="18">
                <div class="left-panel">
                    <div class="fold-panel el-col-lg-0 el-col-md-0 el-col-sm-0 ">
                        <el-dropdown trigger="click" @command="handleCommand">
                          <span class="el-dropdown-link">
                            <i class="el-icon-s-unfold"></i>
                          </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="/" to="/">首頁</el-dropdown-item>
                                <el-dropdown-item :command="item.path" v-for="item in navbarList" :key="item.id">{{item.title}}</el-dropdown-item>
                                <el-dropdown-item command="/category">購物商城</el-dropdown-item>
                                <el-dropdown-item command="/branch" to="/branch">分店資訊</el-dropdown-item>
                                <el-dropdown-item command="/user-center/orders" to="/user-center/orders">訂單查詢</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="logo-panel"><img style="max-height: 60px" :src="logo?logo:require('@/assets/logo.png')" /></div>
                </div>
            </el-col>
            <el-col :xl="10" :lg="10" :md="8" :sm="12" :xs="0">
                <span class="navbar-menu-item" @click="handleCommand('/')">首頁</span>
                <span class="navbar-menu-item" @click="handleCommand(item.path)" v-for="item in navbarList"
                      :key="item.id">{{item.title}}
                </span>
                <span class="navbar-menu-item" @click="handleCommand('/category')">購物商城</span>
                <span class="navbar-menu-item" @click="handleCommand('/branch')">分店資訊</span>
                <span class="navbar-menu-item" @click="handleCommand('/user-center/orders')">訂單查詢</span>
            </el-col>
            <el-col :xl="5" :lg="5" :md="5" class="hidden-sm-and-down">
                <el-input size="small" clearable placeholder="請輸入內容" v-model="searchVal" class="input-with-select" @keyup.enter.native="handleSearch">
                    <el-button slot="append" icon="el-icon-search"  @click="handleSearch"></el-button>
                </el-input>
            </el-col>
            <el-col :xl="3" :lg="3" :md="3" :sm="4" :xs="6">
                <div class="right-icon">
                    <div v-if="cartNum>0">
                        <el-badge :value="cartNum" :max="99" type="danger">
                            <el-button circle size="mini" icon="el-icon-shopping-cart-2" @click="go2Cart"></el-button>
                        </el-badge>
                    </div>
                    <div v-else>
                        <el-button circle size="mini" icon="el-icon-shopping-cart-2" @click="go2Cart"></el-button>
                    </div>

                    <el-dropdown v-if="logined" trigger="click" @command="userHandleCommand">
                        <el-button  circle size="mini" icon="el-icon-user" ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="user-center" to="/">會員中心</el-dropdown-item>
                            <el-dropdown-item command="logout">登出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button v-else  circle size="mini" icon="el-icon-user" @click="go2UserCenter()"></el-button>
                    <div class="hidden-sm-and-down"></div>
                </div>
            </el-col>
        </el-row>
    </el-header>

</template>
<script>
    //store中的action中是可以执行异常操作的，透过content.commit去呼叫mutations中的方法，在组件method中透过this.$store.dispatch('') 或者 mapAction展构定义函数来调用
    //计算属性getters 两种方法1,this.$store.getters.showNum;2,组件computed:{}中展构 ...mapGetters(['showNum'])
    import 'element-ui/lib/theme-chalk/display.css';
    import {GetUserLogout,GetLogo,GetIndexNavbarList} from '@/request/api';
    import {mapState} from 'vuex';//mapState读取store中state中的变量
    // import {mapMutations} from 'vuex';//mapMutation 管理mutation中的方法;在组件method中 1.$store.commit('SET_CARTNUM', res.data.totalQty) 2，...mapMutations(['',''])
    export default {
        props: {
            navBarFixed: Boolean
        },
        components: {},
        data() {
            return {
                logo: '',
                searchVal: '',
                navbarList:[],
            }
        },
        methods: {
            async init(){
                let {data} = await GetLogo();
                this.logo = data.comLogo;
            },
            handleSearch() {
                //if (this.searchVal && this.searchVal.length >= 2) {
                    //this.$router.push('/category');
                    this.$router.push({ path: "/category",query:{kw:this.searchVal} });
                //}
            },
            go2Cart() {
                this.$router.push('/cart');
            },
            go2UserCenter() {
                this.$router.push('/user-center');
            },
            logout() {
                let _this = this;
                this.$confirm('確定要登出嗎？')
                    .then(() => {
                        GetUserLogout({}).then(res => {
                            if (res.code == 200) {
                                localStorage.removeItem("magor3-auth");
                                this.$store.commit('SET_LOGINED', false);
                                this.$router.go(0);
                            } else {
                                console.log(res);
                                _this.$toast.fail('網絡異常,請銷候再試!' + res);

                            }
                        }).catch(e => {
                            console.log(e);
                            _this.$toast.fail('網絡異常,請銷候再試!' + e);
                        });
                    }).catch(() => {
                });
            },
            handleCommand(item) {
                // if (item == this.$route.path) {
                //     return;
                // }
                this.$router.push(item);
            },
            userHandleCommand(item) {
                console.log(item);
                if (item == "logout") {
                    this.logout();
                    return;
                }
                if (item == "user-center") {
                    this.go2UserCenter();
                }
            },
            async getHeaderList(){
                await GetIndexNavbarList().then(res => {
                    if (res.code == 200) {
                        this.navbarList = res.data;
                    }
                });
            },
        },
        mounted() {
            this.init();
            this.getHeaderList();
        },
        computed: {
            ...mapState(['cartNum','logined'])
        },
    }
</script>
<style lang="less" scoped>
    .left-panel {
        display: flex;
        justify-content: flex-start;

        .fold-panel {
            line-height: 50px;
            height: 50px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .navbar-wrapper {
        display: flex;
        align-items: center;
    }

    .el-link {
        margin-right: 10px;
        font-weight: bold;
    }

    .my-navbar {
        height: 60px;
        width: 100%;
        background-color: #f6f7ff;
        color: black;
        font-weight: bold;
    }

    .my-navbar-fix {
        z-index: 999;
        position: fixed;
        top: 0;
    }

    .right-icon {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
    }
    .navbar-menu-item{
        margin: auto 10px;
        cursor: pointer;
    }

</style>
