<template>
    <div>
        <div v-if="(productList||[]) && productList.length">
            <el-row type="flex" justify="start" style="flex-wrap: wrap">
                <el-col :lg="rowCls>0?rowCls:6" :xs="24" :md="12" :key="index" v-for="(item,index) in productList">
                    <el-card style="margin: 10px;" shadow="hover">
                        <img :src="item.image" style="width: 100%;cursor: pointer;" @click="go2Detail(item)"/>
                        <div style="padding: 14px;">
                            <span style="cursor:pointer;" @click="go2Detail(item)">{{item.storeName}}</span>
                            <p style="cursor: pointer" @click="go2Detail(item)">{{ `${item.storeInfo.slice(0,100)}...` }}</p>
                            <div class="prod-card-bottom bottom clearfix">
                                <div class="price">
                                    <span class="list-price">{{item.otPrice | filter_addPricePrefix}}</span>
                                    <span class="sale-price">{{item.price | filter_addPricePrefix}}</span>
                                </div>
                                <div class="btn-cart">
                                    <el-button @click="btnAdd2Cart(item,false)" title="加入購物車" size="small" type="danger" icon="el-icon-shopping-cart-2" circle></el-button>
                                    <el-button @click="btnAdd2Check(item,true)" title="直接購買" size="small" type="warning" icon="el-icon-bank-card" circle></el-button>
                                </div>

                            </div>
                        </div>

                    </el-card>
                </el-col>
            </el-row>
            <el-row style="text-align: center;">
                <el-pagination
                        small background
                        @current-change="handCurrentChange"
                        @prev-click="prevClick"
                        @next-click="nextClick"
                        :hide-on-single-page="true"
                        :page-size="limit"
                        layout="prev, pager, next"
                        :total="total">
                </el-pagination>
            </el-row>
        </div>
        <div v-show="emptFlg">
            <el-row type="flex" justify="center">
                <el-skeleton/>
                <h5>暫時沒有找到商品，請稍候重試！!</h5>
            </el-row>
        </div>
    </div>
</template>
<script>
    import {GetGoodsList} from "@/request/api";
    import {mapActions} from 'vuex';
    export default {
        components: {},
        props: ['rowCls'],
        filters: {
            filter2Int: function (value) {
                if (!value) return '0'
                return parseInt(value);
            }
        },
        data() {
            return {
                emptFlg: false,
                limit: 12,//每頁笔数
                page: 1,//頁数
                total: 0,
                minPrice: 0,
                maxPrice: 0,
                productList: [],
                cid: 0,
                kw: '',
                salesOrder: '',
                addOrder: '',
                priceOrder: '',
            };
        },
        methods: {
            ...mapActions(['add2Cart']),
            setPriceRange(minPrice, maxPrice) {
                this.minPrice = minPrice;
                this.maxPrice=maxPrice;
            },
            go2Detail(prod) {
                this.$router.push('/product/' + prod.id);
            },
            btnAdd2Check(prod) {
                this.btnAdd2Cart(prod, true);
            },
            btnAdd2Cart(prod, toCheckOutFlg) {
                let _this = this;
                var productId = prod.id;
                var productAttrUnique = prod.productSingleAttrUnique;
                var cartNum = 1;
                this.add2Cart({productId, productAttrUnique, cartNum}).then(res => {
                    if (res.code == 200) {
                        if (toCheckOutFlg) {
                            _this.$router.push('/cart');
                        }
                    } else if (res.code == 401) {
                        _this.$router.push('/login');
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            sortGoodsList(order) {
                if (order == 'sales-desc') {
                    this.salesOrder = "desc";
                    this.priceOrder = "";
                    this.addOrder = "";
                    this.getGoodsList(this.cid, this.kw);
                    return;
                }
                if (order == 'price-asc') {
                    this.priceOrder = "asc";
                    this.addOrder = "";
                    this.salesOrder = "";
                    this.getGoodsList(this.cid, this.kw);
                    return;
                }
                if (order == 'price-desc') {
                    this.priceOrder = "desc";
                    this.addOrder = "";
                    this.salesOrder = "";
                    this.getGoodsList(this.cid, this.kw);
                    return;
                }
                if (order == 'add-desc') {
                    this.addOrder = "desc";
                    this.priceOrder = "";
                    this.salesOrder = "";
                    this.getGoodsList(this.cid, this.kw);
                    return;
                }
                if (order == "") {
                    this.addOrder = "";
                    this.priceOrder = "";
                    this.salesOrder = "";
                    this.getGoodsList(this.cid, this.kw);
                    return;
                }
            },
            getGoodsList(cid, kw) {
                let _this = this;
                _this.cid = cid;
                _this.kw = kw;
                const loading = this.$loading({
                    lock: true,
                    text: "加載中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)"
                });
                GetGoodsList({
                    keyword: kw,
                    cid: cid, minPirce: this.minPrice, maxPrice: this.maxPrice,
                    salesOrder: this.salesOrder,
                    addOrder: this.addOrder,
                    priceOrder: this.priceOrder,
                    limit: this.limit, page: this.page, prodType: 1
                }).then(res => {
                    loading.close();
                    if (res.code == 200) {
                        this.$emit('refreshProductList', res.data.list)
                        _this.productList = res.data.list;
                        if (_this.productList.length <= 0) {
                            _this.emptFlg = true;
                        } else {
                            _this.emptFlg = false;
                        }
                        this.total = res.data.total;
                        this.loading = false;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            handCurrentChange(current) {
                this.page = current;
                this.getGoodsList(this.cid, this.kw);
            },
            prevClick() {
                this.page = this.page - 1;
            },
            nextClick() {
                this.page = this.page + 1;
            }
        },
        mounted() {
            if (this.$route.query.kw) {
                this.kw = this.$route.query.kw;
            }
            if (this.$route.params.id) {
                this.cid = this.$route.params.id;
                this.kw = '';
            }
            this.getGoodsList(this.cid, this.kw);
            /*axios
                .get("https://www.themealdb.com/api/json/v1/1/categories.php")
                .then(response => {
                    this.productList = response.data.categories;
                    this.loading=false;
                })
                .catch(err => {
                    this.loading=false;
                    console.log(err);
                });*/
        },
    };
</script>
<style scoped lang="less">
    .prod-card-bottom {
        display: flex;
        justify-content: space-between;

        .price {
            display: flex;
            justify-content: space-between;

            .list-price {
                color: #BDBDBD;
                text-decoration: line-through;
                margin-right: 10px;
            }

            .sale-price {
                font-weight: bold;
                color: #930e11;
            }
        }
    }
</style>
