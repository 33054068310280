//拦截器
import axios from "axios";

//http://127.0.0.1:91/v1
const instance = axios.create({
    // baseURL: process.env.VUE_APP_URL,//http://line2.i-webee.com.tw:91/v1
    //baseURL:'http://line2.i-webee.com.tw:91/v1',
    baseURL:'/',
    timeout: 15000
})

instance.interceptors.request.use(config => {
    config.headers["site-style"] = 'goose';
    let authstr = localStorage.getItem("magor3-auth");
    if (authstr && authstr != undefined) {
        let auth = JSON.parse(authstr);
        if (auth && auth != "{}" && auth.token && auth.token != undefined) {
            // config.headers.Authroization=token;
            config.headers["Authori-zation"] = auth.token;
        }
    }
    return config;
}, err => {
    return Promise.reject(err)
})

instance.interceptors.response.use(res => {
    return res.data;
}, err => {
    return Promise.reject(err)
})

export default instance;
