import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementUI from 'element-ui';
Vue.use(ElementUI);

import VueCookies from 'vue-cookies'; // 引入cookies 插件
Vue.use(VueCookies);
import CryptoJS from 'crypto-js';
Vue.prototype.CryptoJS = CryptoJS;

import 'element-ui/lib/theme-chalk/index.css';

import 'default-passive-events';
Vue.config.productionTip = false

Vue.filter('filter_addPricePrefix', function (value) {
    if (!value) return '0'
    return "NT$ "+parseInt(value);
})


Vue.filter('filter_payChannel', function (value) {
    if (!value) return '';
    if ("CVS" == value) {
        return "隨機超商代碼繳費";
    } else if ("ATM" == value) {
        return "ATM虛擬帳號轉帳";
    } else if ("FAMILYS" == value) {
        return "全家超商代碼繳費";
    } else if ("IBONS" == value) {
        return "7-11超商代碼繳費";
    } else if ("HILIFE" == value) {
        return "萊爾富超商代碼繳費";
    } else if ("OKMART" == value) {
        return "OK超商代碼繳費";
    } else {
        return '';
    }
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
